.brand{
    font-size: 40px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 50px;
}

.username{
    font-size: 18px;
    margin-top: 40px;
    text-align: center;
}