.container{
    height: 100vh;
    background-color: #1e1e1e;
    overflow: hidden;
}

.heading{
    color: #fff;
    font-size: 26px;
    margin-left: 25px;
}

.fileName{
    color: #000!important;
    padding: 8px;
    font-size: 15px;
    border: 2px solid #000;
    outline: 0;
    border-radius: 5px;
    margin-right: 10px;
}

.inputBox{
    width: 100%;
    margin-top: 15px;
    background-color: #23272A;
    color: #fff;
    outline: 0;
    height: 86%;
    border: 1.5px solid #5663F7;
    border-radius: 5px;
    padding: 1px;

}

.headText{
    text-align: center;
}

.alertToast{
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
}