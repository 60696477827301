.container{
    height: 100%;
}

.formContainer{
    width: 50%;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

input{
    color: #fff!important;
    border-color: #fff!important;
}

label{
    color: #fff!important;
}

.noAccount{
    text-align: left;
    margin-top: 40px;
    font-weight: 500;
    cursor: pointer;
}

.sample div::before{
    border-color: #fff!important;
}

