*{
    margin: 0;
    padding: 0;
}

.loadingBlk{
    text-align: center;
    background-color: #23272A;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container{
    height: 100vh;
    color: #fff;
    background-color: #23272A;
}

.codesHead{
    margin: 30px auto;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}

.wrapper{
    height: 100%;
}

.codingImg{
    max-width: 35%;
    display: block;
    animation: upDown 2s linear infinite reverse;
}

@keyframes upDown{
    0%, 100%{
        transform: translateY(-10px);
    }
    50%{
        transform: translateY(10px);
    }

}